<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries">
        <div class="heading">Our Gallery</div>
        <div class="content">
          <page-link :to="page.parent.url" class="gallery-back" v-if="page.parent">
            <img alt="Back" src="../assets/reel.jpg" />
            <span>
              Go back to
              <span>{{ page.parent.name }}</span> Category
            </span>
          </page-link>
          <h2 v-if="page.parent">{{ page.parent.name }}</h2>

          <p v-if="page.parent">Gallery > {{ page.parent.name }} > {{ page.name }}</p>

          <div class="thumbnail-heading">{{ page.name}}</div>
          <template v-if="page.images && page.images.length">
            <slide-show :cycle="false" @slide-change="pagenumber = $event" ref="slideshow">
              <div :key="slide" class="thumbnail-list" v-for="slide in numberOfPages">
                <page-link
                  :key="image._id"
                  :to="'file/' + page.ItemID + '/' + image._id"
                  v-for="image in pageSlice(slide)"
                >
                  <span class="img">
                    <img :alt="filenameFromPath(image.image)" :src="image.image" />
                  </span>
                  <!--<span class="filename">{{ filenameFromPath(image.image) }}</span>-->
                </page-link>
              </div>
            </slide-show>
            <div class="thumbnail-footer">
              <div class="paging">
                Page:
                <a
                  :class="{ active: i == pagenumber }"
                  :key="i"
                  @click.prevent="$refs.slideshow.show(i)"
                  v-for="i in numberOfPages"
                >{{i}}</a>
              </div>
              <div class="total">Files: {{ page.images.length }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  data: function() {
    return { pageSize: 15, pagenumber: 1 }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"]),

    numberOfPages: function() {
      if (!this.page.images) return 0
      return Math.ceil(this.page.images.length / this.pageSize)
    }
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("show")
    },
    filenameFromPath(path) {
      return path.split("/")[path.split("/").length - 1]
    },
    pageSlice: function(i) {
      if (!this.page.images) return []

      return this.page.images.slice(i * this.pageSize - this.pageSize, i * this.pageSize)
    }
  }
}
</script>

<style scoped>
.thumbnail-heading,
.thumbnail-footer {
  background: #e9e9e9;
  border-bottom: 4px solid #0092c0;
  padding: 1rem;
  margin: 1rem 0;
  margin-top: 2rem;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}
.thumbnail-footer {
  border-bottom: 0;
  display: table;
  width: 100%;
}
.paging {
  float: right;
}
.paging a {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-left: 0.3rem;
  color: white;
  background: #00ab7d;
  cursor: pointer;
}
.paging a.active {
  background: #0092c0;
}
.thumbnail-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .thumbnail-list > * {
    float: left;
    width: 31%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
}
.thumbnail-list a {
  position: relative;
  line-height: 0;
}
.thumbnail-list a img {
  width: 100%;
}
.thumbnail-list a .img {
  max-height: 13.5rem;
  overflow: hidden;
  display: block;
}
.thumbnail-list a .filename {
  position: absolute;
  display: block;
  line-height: 1.2;
  padding: 0.4rem;
  color: white;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 171, 125, 0.6);
  font-size: 1.2rem;
}
@media screen and (max-width: 750px) {
  .thumbnail-list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
