<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries">
        <div class="heading">Our Gallery</div>
        <div class="content">
          <page-link :to="page.url" class="gallery-back">
            <img alt="Back" src="../assets/reel.jpg" />
            <span>
              Go back to
              <span>{{ page.name }}</span> Album
            </span>
          </page-link>
          <h2>{{ page.name }}</h2>

          <p v-if="page.parent">Gallery > {{ page.parent.name }} > {{ page.name }}</p>

          <div class="grid-image">
            <div class="grid-left">
              <div class="image-heading">{{ currentImage.filename }}</div>
              <img
                :alt="currentImage.filename"
                :src="currentImage.image"
                @load="imageLoaded"
                class="image"
                id="img"
              />
              <div class="image-info">
                File Information
                <div class="leftright">
                  <page-link
                    :to="'file/' + page.ItemID + '/' + prevImage._id"
                    class="left"
                    v-if="prevImage"
                  >&larr;</page-link>
                  <page-link
                    :to="'file/' + page.ItemID + '/' + nextImage._id"
                    class="right"
                    v-if="nextImage"
                  >&rarr;</page-link>
                </div>
              </div>
              <table class="image-data">
                <tr>
                  <td class="td-label">Filename:</td>
                  <td class="td-data">{{ currentImage.filename }}</td>
                </tr>
                <tr v-if="currentImage.description">
                  <td class="td-label">Description:</td>
                  <td class="td-data">{{ currentImage.description }}</td>
                </tr>
                <tr>
                  <td class="td-label">Album name:</td>
                  <td class="td-data">{{ page.name }}</td>
                </tr>
                <tr v-if="filesize">
                  <td class="td-label">File Size:</td>
                  <td class="td-data">{{ filesize }} KB</td>
                </tr>
                <tr>
                  <td class="td-label">Date added:</td>
                  <td class="td-data">{{ currentImage.formattedDate }}</td>
                </tr>
                <tr v-if="dimensions">
                  <td class="td-label">Dimensions:</td>
                  <td class="td-data">{{ dimensions }} pixels</td>
                </tr>
                <tr>
                  <td class="td-label">Displayed:</td>
                  <td class="td-data">{{ currentImage.hits }} times</td>
                </tr>
                <tr>
                  <td class="td-label">URL:</td>
                  <td class="td-data">{{ currentImage.image }}</td>
                </tr>
                <!-- <tr>
                  <td class="td-label">Favourites:</td>
                  <td class="td-data">
                    <a href>Add to favourites</a>
                  </td>
                </tr>-->
                <template v-if="approvedComments.length">
                  <tr>
                    <td class="td-label" colspan="2">Comments:</td>
                  </tr>
                  <template v-for="comment in approvedComments">
                    <tr :key="comment._id">
                      <td class="td-data" colspan="2" v-html="comment.message"></td>
                    </tr>
                    <tr :key="comment._id + 'b'">
                      <td class="td-label">User:</td>
                      <td class="td-data">{{ comment.name }} ({{ formatDate(comment.date) }})</td>
                    </tr>
                  </template>
                </template>

                <tr>
                  <td class="td-heading" colspan="2">Add your comment</td>
                </tr>
                <tr>
                  <td class="td-label">Name:</td>
                  <td class="td-data">
                    <input class="comment-name" type="text" v-model.trim="comment_name" />
                  </td>
                </tr>
                <tr>
                  <td class="td-label" colspan="2">
                    Comment:
                    <textarea class="comment-content" v-model.trim="comment_content"></textarea>
                  </td>
                </tr>
                <tr>
                  <td class="td-heading" colspan="2">
                    <div v-if="comment_success">Thanks, your comment has been submitted for review!</div>
                    <div v-if="comment_error">Sorry, there was an error posting your comment</div>
                    <button @click="postComment" class="btn">Submit</button>
                  </td>
                </tr>
              </table>
            </div>
            <div class="grid-right">
              <page-link
                :to="'file/' + page.ItemID + '/' + prevImage._id"
                class="prev"
                v-if="prevImage"
              >
                <span class="label">Previous</span>
                <span class="contain">
                  <span class="arrow">&larr;</span>
                  <img :src="prevImage.image" />
                </span>
              </page-link>
              <page-link
                :to="'file/' + page.ItemID + '/' + nextImage._id"
                class="prev"
                v-if="nextImage"
              >
                <span class="contain">
                  <img :src="nextImage.image" />
                  <span class="arrow">&rarr;</span>
                </span>
                <span class="label">Next</span>
              </page-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"
import dateUtil from "date-and-time"
import api from "@/services/api"

export default {
  data() {
    return {
      dimensions: "",
      filesize: "",
      comment_name: "",
      comment_content: "",
      comment_error: false,
      comment_success: false
    }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"]),
    prevImage() {
      if (!this.page.images) return null
      var i = this.page.images.findIndex(i => i._id == this.$route.params.fileid)

      if (i < 1) return null
      else return this.page.images[i - 1]
    },
    nextImage() {
      if (!this.page.images) return null
      var i = this.page.images.findIndex(i => i._id == this.$route.params.fileid)

      if (i == this.page.images.length - 1) return null
      else return this.page.images[i + 1]
    },
    currentImage() {
      if (!this.page.images) return {}

      var image = this.page.images.filter(i => i._id == this.$route.params.fileid)[0]
      image.filename = image.image.split("/")[image.image.split("/").length - 1]
      image.formattedDate = dateUtil.format(new Date(image.date_added * 1000), "DD MMM, YYYY")
      return image
    },
    approvedComments() {
      if (!this.currentImage.comments) return []

      return this.currentImage.comments.filter(c => c.approved)
    }
  },
  methods: {
    postComment() {
      this.comment_success = false
      this.comment_error = false

      if (this.comment_name && this.comment_content)
        api
          .postComment({
            name: this.comment_name,
            message: this.comment_content.replace(/<[^>]*>?/gm, ""),
            fileid: this.$route.params.fileid,
            pageid: this.$route.params.pageid
          })
          .then(res => {
            if (res.status == 200)
              api.sendEmail(
                "New comment posted",
                "<p style='font-family:Arial'>A new comment has been submitted via the website.<br /><br />" +
                  "<strong>Name:</strong> " +
                  this.comment_name +
                  "<br /><br /><strong>Comment:</strong> " +
                  this.comment_content +
                  "<br /><br /><strong>Image:</strong><br /><br />" +
                  "<img width='250px' src='" +
                  this.currentImage.image +
                  "' /><br /><br />" +
                  "<strong>Album:</strong> " +
                  this.page.name +
                  "" +
                  "<br /><br />Please log in to the website admin area to approve it.</p>"
              )

            this.comment_name = ""
            this.comment_content = ""

            this.comment_success = true
          })
          .catch(() => (this.comment_error = true))
    },
    formatDate(d) {
      return dateUtil.format(new Date(d), "DD.MM.YYYY")
    },
    toggle(event) {
      event.target.classList.toggle("show")
    },
    imageLoaded() {
      var img = document.getElementById("img")
      this.dimensions = img.naturalWidth + " x " + img.naturalHeight

      var xhr = new XMLHttpRequest()
      xhr.open("HEAD", this.currentImage.image, true)

      var self = this
      xhr.onreadystatechange = function() {
        if (this.readyState == this.DONE)
          self.filesize = (parseInt(xhr.getResponseHeader("Content-Length")) / 1024).toFixed(0)
      }
      xhr.addEventListener("error", function() {
        xhr.send()
      })
      xhr.send()
    }
  }
}
</script>

<style scoped>
.grid-image {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid-image {
    display: table;
    width: 100%;
  }
  .grid-left {
    float: left;
    width: 70%;
    margin-right: 2%;
  }
  .grid-right {
    float: left;
    width: 28%;
  }
}

.image-data {
  width: 100%;
  border-collapse: collapse;
  border: 0;
}

.image-data td {
  background: #f2fbf8;
  padding: 0.5rem 1rem;
}

.image-data tr:nth-child(even) td {
  background: #e5f7f2;
}

.td-label {
  color: #003779;
}

.td-heading {
  background: white !important;
  color: #003779;
  font-size: 2rem;
  padding: 1rem !important;
}

.comment-name {
  width: 100%;
  padding: 0.5rem;
}

.comment-content {
  width: 100%;
  height: 10rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.image {
  width: 100%;
}

.image-heading {
  background: #e9e9e9;
  border-bottom: 4px solid #0092c0;
  padding: 1rem;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.label {
  display: block;
  padding: 0.25rem 1rem;
  color: white;
  background: #00ab7d;
  margin-bottom: 0.5rem;
  transition: all 0.2s;
}

.btn {
  padding: 0.5rem 2rem;
  color: white;
  background: #00ab7d;
  margin-bottom: 0.5rem;
  transition: all 0.2s;
  border: 0;
  cursor: pointer;
}

.contain {
  position: relative;
  display: block;
  overflow: hidden;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 4rem;
  padding: 0.5rem;
  height: 4rem;
  clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
}

.next .arrow {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  text-align: right;
  padding-top: 1rem;
}

.grid-right a:hover .label,
.btn:hover {
  background: #0092c0;
}

.image-info {
  background: #5b5b5b;
  color: white;
  font-size: 2rem;
  padding: 1rem;
}

.leftright {
  float: right;
}

.leftright a {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: white;
  background: #00ab7d;
  transition: all 0.2s;
  line-height: 1;
  margin-left: 1rem;
  transition: all 0.2s;
}

.leftright a:hover {
  background: #0092c0;
}

@media screen and (max-width: 880px) {
  .grid-image {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 750px) {
  .grid-image {
    grid-template-columns: 1fr;
  }
  .grid-right a {
    display: block;
    width: 48%;
    float: left;
  }
  .grid-right img {
    width: 100%;
  }
  .grid-right .prev {
    margin-right: 4%;
  }
}
</style>
