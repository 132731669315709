<template>
  <div
    :class="{ padded: $route.name !== 'item' }"
    class="banners"
    v-if="page.banners && page.banners.length"
  >
    <div class="cycle-slideshow">
      <slide-show class="slides">
        <div :key="image._id" class="slide" v-for="image in page.banners">
          <img :alt="image.alt || 'Tickhill History Society'" :src="image.image" />
          <router-link
            :to="image.url || ''"
            class="caption"
            v-if="isHomePage && image.caption_line_1"
            :aria-label="image.caption_line_1"
          >
            <span class="caption-1" v-if="image.caption_line_1">{{ image.caption_line_1 }}</span>
            <span class="caption-2" v-if="image.caption_line_2">{{ image.caption_line_2 }}</span>
          </router-link>
        </div>
      </slide-show>
      <img alt="Tickhill History" class="frame-top" src="../assets/frame-top.png" />
      <img alt="Tickhill History" class="frame-bottom" src="../assets/frame-bottom.png" />
      <!-- <div class="pager" v-if="isHomePage">
        <a class="current" href>
          <font-awesome-icon icon="square-full" />
        </a>
        <a href>
          <font-awesome-icon icon="square-full" />
        </a>
        <a href>
          <font-awesome-icon icon="square-full" />
        </a>
        <a href>
          <font-awesome-icon icon="square-full" />
        </a>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.banners {
  position: relative;
  margin-top: 0.2rem;
}

.banners.padded {
  margin-top: 2rem;
}

.frame-top {
  position: absolute;
  top: -5px;
  left: 0;
  max-width: 100%;
  pointer-events: none;
}

.frame-bottom {
  position: absolute;
  bottom: -0;
  right: 0;
  max-width: 8%;
}

.slide {
  width: 100%;
}

.slide img {
  width: 96%;
  margin: auto;
  display: block;
}

.pager {
  position: absolute;
  bottom: 1rem;
  left: 4rem;
}

.pager a.current {
  color: #00a97c;
}

.pager a {
  margin-right: 0.5rem;
  color: #656565;
}

.caption {
  position: absolute;
  top: 3rem;
  left: 1rem;
  display: block;
}

.caption-1 {
  background: rgba(0, 55, 122, 0.85);
  display: block;
  color: white;
  font-size: 2.4rem;
  padding: 0.5rem 2rem;
  padding-right: 4rem;
}

.caption-2 {
  background: #00aa7d;
  display: block;
  color: white;
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  padding-right: 4rem;
  margin-top: 0.5rem;
  -webkit-clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
}

@media screen and (max-width: 750px) {
  .caption {
    position: relative;
    top: 0;
    left: 2%;
    width: 96%;
  }

  .frame-bottom {
    display: none;
  }

  .pager {
    position: relative;
    top: 1rem;
    left: 2%;
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .frame-top {
    top: -3px;
  }
}
</style>
