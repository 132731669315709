<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries">
        <div class="heading" v-if="isGalleryPage">Our Gallery</div>
        <div class="heading" v-else>{{ page.name }}</div>

        <div class="content">
          <template v-if="isGalleryPage">
            <h2>Places</h2>

            <span v-html="page.places_archive_text"></span>

            <div class="articles">
              <page-link :key="place.ItemID" :to="place.url" class="entry" v-for="place in page.places_archive_pages">
                <span class="entry-name">{{ place.name }}</span>
                <span class="entry-info">
                  <img :alt="place.name" :src="place.image" class="entry-image" />
                  <span class="entry-stats" v-if="place.albums">
                    Albums: {{ place.albums.length }}
                    <br />
                    Files: {{ place.imageCount }}
                  </span>
                  <span class="entry-go">GO</span>
                </span>
              </page-link>
            </div>
            <div class="heading">Tickhill Today</div>
            <div class="content">
              <h2>Tickhill Today</h2>

              <span v-html="page.tickhill_today_text"></span>

              <div class="articles">
                <page-link :key="today.ItemID" :to="today.url" class="entry" v-for="today in page.tickhill_today_pages">
                  <span class="entry-name">{{ today.name }}</span>
                  <span class="entry-info">
                    <img :alt="today.name" :src="today.image" class="entry-image" />
                    <span class="entry-stats" v-if="today.albums">
                      Albums: {{ today.albums.length }}
                      <br />
                      Files: {{ today.imageCount }}
                    </span>
                    <span class="entry-go">GO</span>
                  </span>
                </page-link>
              </div>
            </div>
          </template>

          <template v-if="isTickhillToday ||isPlacesArchive">
            <div class="articles">
              <page-link :key="album.ItemID" :to="album.url" class="entry" v-for="album in page.albums">
                <span class="entry-name">{{ album.name }}</span>
                <span class="entry-info">
                  <img
                    :alt="album.name"
                    :src="album.image ? album.image : album.images && album.images.length ? album.images[0].image : 'placeholder.jpg'"
                    class="entry-image"
                  />
                  <span class="entry-stats" v-if="album.images">Files: {{ album.images.length }}</span>
                  <span class="entry-go">GO</span>
                </span>
              </page-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapGetters(["isGalleryPage", "isTickhillToday", "isPlacesArchive"]),
    ...mapState(["page"])
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("show")
    }
  }
}
</script>
