<template>
  <footer>
    <div class="grid">
      <div>
        <h4>Site Map</h4>
        <page-link :key="menuPage.ItemID" :to="menuPage.url || '/'" v-for="menuPage in main_pages">{{ menuPage.name }}</page-link>
      </div>
      <div class="contacts" v-if="settings && settings.contacts">
        <h4>Contacts</h4>
        <div :key="contact._id" v-for="contact in settings.contacts">
          <strong>{{ contact.role }}</strong>
          | {{ contact.name }}
        </div>
      </div>
      <div class="strapline" v-if="settings && settings.strapline">
        <div>{{ settings.strapline }}</div>
      </div>
    </div>
    <div class="copyright" v-if="settings && settings.copyright_notice">&copy; {{ settings.copyright_notice }}</div>
  </footer>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page", "main_pages", "settings"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid {
  padding: 3rem 3%;
  background: #008fbe;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 5rem;
  color: white;
  font-size: 1.2rem;
}

.copyright {
  background: #003a7b;
  color: white;
  padding: 1rem 3%;
}

h4 {
  font-size: 2rem;
  color: #00367a;
  font-weight: normal;
  border-bottom: 1px solid #00367a;
  margin-bottom: 2rem;
}

.grid a,
.contacts div {
  display: block;
  margin-bottom: 0.3rem;
}

.strapline {
  display: flex;
  align-items: flex-end;font-family: 'Cookie', cursive;
  color: #00367a;
  font-size: 5.5rem;
  line-height: 1;
}

.strapline > div {
  text-align: right;
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > div {
    float: left;
    width: 22%;
    margin-right: 3%;
  }
  .grid > div.strapline {
    width: 50%;
    margin-right: 0;
  }
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>
