<template>
  <header>
    <router-link class="logo" to="/">
      <img alt="Tickhill History Society" src="../assets/logo.png" />
    </router-link>
    <img alt="National Lottery Funded" class="lottery" src="../assets/lottery.jpg" />
    <div class="search">
      <input
        @keyup.enter="search"
        aria-label="Search our site"
        placeholder="Search our site"
        type="text"
        v-model.trim="query"
      />
      <button @click="search" aria-label="Search">
        <font-awesome-icon icon="search" />
      </button>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      query: ""
    }
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  },
  methods: {
    search() {
      if (this.query) this.$router.push("/search?query=" + this.query)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  padding: 3rem 5rem 2rem 10rem;
  position: relative;
}

.logo {
  max-width: 30%;
  display: inline-block;
}

.lottery {
  float: right;
  max-width: 12%;
}

.search {
  position: absolute;
  right: 5rem;
  bottom: 2.5rem;
  border-top: 2px solid #90938b;
  border-right: 2px solid #90938b;
  border-bottom: 2px solid #c8c9c5;
  border-left: 2px solid #c8c9c5;
  margin-top: 1.8rem;
}

.search input {
  border: 0;
  padding: 1rem;
  width: 30rem;
}

.search button {
  border: 0;
  background: transparent;
  color: #90938b;
  font-size: 2rem;
  padding: 0 1rem;
  margin-top: 0.7rem;
  border-left: 1px solid #90938b;
}

@media screen and (max-width: 1000px) {
  header {
    padding: 3rem;
  }
  .search {
    right: 3rem;
    bottom: auto;
    top: 45%;
  }
  .search input {
    width: 20rem;
  }
}

@media screen and (max-width: 880px) {
  header {
    padding-left: 10rem;
  }
}

@media screen and (max-width: 500px) {
  header {
    padding: 2rem;
    padding-top: 3rem;
    padding-left: 8rem;
  }
  .lottery {
    display: none;
  }
  .search {
    top: 1.3rem;
  }
  .search input {
    width: 15rem;
    padding: 0.5rem;
  }
  .search button {
    font-size: 2rem;
    padding: 0 0.5rem;
    margin-top: 0.4rem;
  }
}
</style>
