<template>
  <div>
    <header-elem />
    <navigation-elem />
    <banners-elem />
    <content-elem v-if="isGeneral || isGeneralSub || isArticle" />
    <news-elem v-if="isNewsPage" />
    <sub-elem v-if="isNewsSub || isArticlesSub || isSearchPage" />
    <articles-elem v-if="isArticlesPage || isMemoriesPage || isMemoriesSub" />
    <gallery-elem v-if="isGalleryPage || isPlacesArchive || isTickhillToday" />
    <gallery-sub v-if="isAlbum" />
    <gallery-image v-if="isFile" />
    <footer-elem />
  </div>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import FooterElem from "@/components/Footer.vue"
import NavigationElem from "@/components/Navigation.vue"
import BannersElem from "@/components/Banners.vue"
import ContentElem from "@/components/Content.vue"
import NewsElem from "@/components/News.vue"
import SubElem from "@/components/Sub.vue"
import ArticlesElem from "@/components/Articles.vue"
import GalleryElem from "@/components/Gallery.vue"
import GallerySub from "@/components/GallerySub.vue"
import GalleryImage from "@/components/GalleryImage.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    FooterElem,
    ContentElem,
    NavigationElem,
    BannersElem,
    NewsElem,
    ArticlesElem,
    GalleryElem,
    SubElem,
    GallerySub,
    GalleryImage
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters([
      "isHomePage",
      "isNewsPage",
      "isGalleryPage",
      "isArticlesPage",
      "isPlacesArchive",
      "isTickhillToday",
      "isAlbum",
      "isSub",
      "isGeneralSub",
      "isGeneral",
      "isFile",
      "isArticleNewsGallerySub",
      "isNewsSub",
      "isArticlesSub",
      "isArticle",
      "isMemoriesPage",
      "isMemoriesSub",
      "isSearchPage"
    ])
  },
  metaInfo() {
    return {
      title: this.page.title || "Tickhill History",
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function() {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store
    // var state = this.$store.state

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "root") promises.push(api.getHomePage())
    if (this.isFile) promises.push(api.getPageByItemID(route.params.pageid))

    Promise.all(promises).then(vals => {
      var returnedpage = vals[0]
      store.commit("setPage", returnedpage)

      api
        .getPages("summary,image", "page.article=@" + this.page.ItemID)
        .then(articles => this.$set(this.page, "articles", articles))

      if (this.isSub || this.isNewsSub)
        api.getPages("image", "page.sub=@" + this.page.ParentID).then(subs => {
          subs.forEach(sub =>
            api.getPages("", "page.article=@" + sub.ItemID).then(articles => this.$set(sub, "articles", articles))
          )

          this.$set(this.page, "subs", subs)
        })
      else
        api.getPages("image", "page.sub=@" + this.page.ItemID).then(subs => {
          subs.forEach(sub =>
            api.getPages("", "page.article=@" + sub.ItemID).then(articles => this.$set(sub, "articles", articles))
          )

          this.$set(this.page, "subs", subs)
        })

      if (this.isNewsPage || this.isNewsSub)
        api
          .getPages(
            "#date,#image,pdf",
            "page.newsletter=@" + (this.isNewsPage ? this.page.ItemID : this.page.ParentID),
            "",
            {
              "#date": "date",
              "#image": "image"
            }
          )
          .then(subs =>
            this.$set(
              this.page,
              "newsletters",
              subs.sort((a, b) => a.date > b.date)
            )
          )

      if (this.isPlacesArchive || this.isTickhillToday)
        api.getPages("image,images", "page.album=@" + this.page.ItemID).then(albums => {
          this.$set(this.page, "albums", albums)
        })

      if (this.isSearchPage)
        api
          .getPages(
            "ParentID,meta_keywords,image,images,summary,main_text,#search",
            "page.",
            "contains(#search,:query)",
            { "#search": "_search" },
            { ":query": this.$route.query.query.toLowerCase() }
          )
          .then(res => {
            this.$set(this.page, "articles", [])

            res.forEach(p => {
              var pr = []
              if (p.ParentID) pr.push(api.getPageByItemID(p.ParentID))

              Promise.all(pr).then(vals => {
                if (vals.length && !vals[0].active) return

                if (
                  (p.name && p.name.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) > -1) ||
                  (p.meta_keywords &&
                    p.meta_keywords.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) > -1) ||
                  (p.main_text && p.main_text.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) > -1)
                )
                  this.page.articles.push(p)

                if (p.images && p.images.length)
                  this.page.articles = this.page.articles.concat(
                    p.images.filter(i => {
                      if (
                        i.description &&
                        i.description.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) > -1
                      ) {
                        i.parent = p.ItemID
                        return true
                      }

                      if (
                        i.comments &&
                        i.comments.filter(
                          c => c.message.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) > -1
                        ).length
                      ) {
                        i.parent = p.ItemID
                        return true
                      }
                    })
                  )
              })
            })
          })
          .catch(err => console.log(err))

      if (this.isGalleryPage || this.isPlacesArchive || this.isTickhillToday || this.isAlbum || this.isFile) {
        api.getPages("image", "page.placesarchive").then(places => {
          this.$set(this.page, "places_archive_pages", places)
          places.forEach(place => {
            place.imageCount = 0
            api.getPages("images", "page.album=@" + place.ItemID).then(albums => {
              this.$set(place, "albums", albums)
              place.imageCount = 0

              albums.forEach(album => {
                if (album.images) place.imageCount += album.images.length
              })
            })
          })
        })

        api.getPages("image", "page.tickhilltoday").then(todays => {
          this.$set(this.page, "tickhill_today_pages", todays)
          todays.forEach(today => {
            api.getPages("images", "page.album=@" + today.ItemID).then(albums => {
              this.$set(today, "albums", albums)
              today.imageCount = 0

              albums.forEach(album => {
                if (album.images) today.imageCount += album.images.length
              })
            })
          })
        })
      }

      if (!returnedpage || !returnedpage.ItemID)
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

      if (this.page.ParentID) api.getPageByItemID(this.page.ParentID).then(parent => (this.page.parent = parent))
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
  }
}
</script>
