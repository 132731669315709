<template>
  <section>
    <a @click.prevent="showMenu = !showMenu" class="mobile-menu" href id="mobile-menu-btn">
      <font-awesome-icon icon="bars" v-show="!showMenu" />
      <font-awesome-icon icon="times" v-show="showMenu" />
    </a>
    <nav :class="{ open: showMenu }">
      <div class="inner">
        <ul>
          <li
            :class="{ sel: (page.ItemID == menuPage.ItemID || page.ParentID == menuPage.ItemID) }"
            :key="menuPage.ItemID"
            v-for="menuPage in main_pages"
          >
            <page-link :to="menuPage.url || '/'">{{ menuPage.name }}</page-link>
          </li>
        </ul>
      </div>
    </nav>
  </section>
</template>

<script>
import { mapState } from "vuex"

export default {
  data: function() {
    return { showMenu: false }
  },
  computed: {
    ...mapState(["page", "main_pages"])
  },
  methods: {
    toggleSubMenu: event => {
      var li = event.srcElement.closest("li")
      li.classList.toggle("open")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  position: relative;
  z-index: 2;
  padding-left: 4rem;
  margin-top: 1rem;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
  text-align: left;
}

nav ul li {
  display: inline-block;
  text-align: left;
}

nav ul li.sel a {
  color: #00ab7d;
}

nav a {
  display: block;
  border-right: 1px solid #ccc;
  margin-right: 1rem;
  padding-right: 1rem;
  color: #666666;
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  font-weight: bold;
}

nav li:last-child a {
  border-right: 0;
}

nav a:hover {
  color: #22568e;
}

/* Hide Dropdowns by Default */
nav ul ul {
  display: none;
  position: absolute;
  top: 6rem; /* the height of the main nav */
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
}

nav ul ul li a {
  line-height: 4rem;
}

/* Second, Third and more Tiers	*/
nav ul ul ul li {
  position: relative;
  top: 7rem;
  left: 0;
}
nav .down {
  padding: 0rem 0.5rem;
  opacity: 0.3;
  position: relative;
}

nav .down .fa-chevron-up {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: -0.9rem;
  left: 3%;
  font-size: 4.5rem;
  color: #656565;
}

.mobile-menu > * {
  position: absolute;
  left: 0;
  top: 2rem;
  transition: all 0.2s;
  z-index: 9999;
}

.mobile-menu .fa-times {
  margin-left: 0.5rem;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  nav {
    padding-left: 3rem;
  }
}

@media screen and (min-width: 881px) {
  /* Display Dropdowns on Hover */
  nav ul li:hover > ul {
    display: inherit;
  }
}

@media screen and (max-width: 880px) {
  nav {
    position: absolute;
    top: 0;
    right: 100%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9998;
    min-height: calc(100vh);
    padding-top: 10rem;
    margin: 0;
    padding-left: 0;
    background-color: #656565;
  }
  nav .down {
    padding: 0.8rem 1rem;
    padding-left: 1.5rem;
    top: -0.8rem;
    right: -1rem;
    position: relative;
    opacity: 1;
  }

  nav .down {
    display: block;
    float: right;
  }
  nav.open {
    right: 0;
  }
  nav ul {
    padding-bottom: 2rem;
  }
  nav ul a {
    padding: 1rem 3rem;
    line-height: 1.2;
    font-size: 2rem;
    border-right: 0;
    color: white;
    text-align: center;
  }
  nav ul ul li a {
    line-height: 1.2;
  }
  nav ul li {
    display: block;
  }
  nav ul ul {
    position: relative;
    top: 0;
    left: 2rem;
    width: calc(100% - 4rem);
    padding-bottom: 1rem;
  }
  nav ul li.open ul {
    display: block;
  }
  nav a:hover {
    color: white;
  }

  nav li.open .down .fa-chevron-up {
    display: block;
  }

  nav li.open .down .fa-chevron-down {
    display: none;
  }

  .search {
    float: none;
    margin-bottom: 2rem;
  }
  .search-input {
    width: 90%;
  }
  .search-button {
    float: right;
  }
  .mobile-menu {
    display: block;
    top: 0.5rem;
  }
}

@media screen and (max-width: 650px) {
  .mobile-menu {
    font-size: 4.5rem;
  }
}
</style>
