<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries" v-if="isArticlesPage">
        <div class="heading">Articles</div>
        <div class="content">
          <span v-html="page.main_text"></span>

          <div class="articles">
            <page-link :key="sub.ItemID" :to="sub.url" class="entry" v-for="sub in page.subs">
              <span class="entry-name">{{ sub.name }}</span>
              <span class="entry-info">
                <img :alt="sub.name" :src="sub.image" class="entry-image" />
                <span class="entry-stats" v-if="sub.articles">Articles: {{ sub.articles.length }}</span>
                <span class="entry-go">GO</span>
              </span>
            </page-link>
          </div>
        </div>
      </div>
      <div class="entries" v-if="isMemoriesPage || isMemoriesSub">
        <div class="heading">{{page.name}}</div>
        <div class="contentsection">
          <span v-html="page.main_text"></span>

          <div class="pdfs" v-if="page.downloads && page.downloads.length">
            <a
              :href="download.pdf"
              :key="download.pdf"
              class="pdf"
              rel="noreferrer"
              target="_blank"
              v-for="download in page.downloads"
            >
              <img alt="PDF" src="../assets/pdf.jpg" />
              <span>
                <strong>{{download.name}}</strong> (PDF Document)
              </span>
            </a>
          </div>
        </div>

        <div class="articles" v-if="isMemoriesPage">
          <page-link :key="sub.ItemID" :to="sub.url" class="entry" v-for="sub in page.subs">
            <span class="entry-name">{{ sub.name }}</span>
            <span class="entry-info">
              <img :alt="sub.name" :src="sub.image" class="entry-image" />
              <span class="entry-go">GO</span>
            </span>
          </page-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"
import BannersElem from "@/components/Banners.vue"

export default {
  components: {
    BannersElem
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isArticlesPage", "isMemoriesPage", "isMemoriesSub"])
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("show")
    }
  }
}
</script>
