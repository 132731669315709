<template>
  <section class="contentsection">
    <div class="heading" v-if="isHomePage">{{ page.heading }}</div>
    <div class="grid standard">
      <div class="padded">
        <div class="breadcrumb" v-if="page.parent">
          <page-link :href="this.page.parent.url">{{ this.page.parent.name }}</page-link>
          > {{ this.page.name }}
        </div>

        <span v-html="page.main_text"></span>

        <div class="pdfs" v-if="page.downloads && page.downloads.length">
          <a
            :href="download.pdf"
            :key="download.pdf"
            class="pdf"
            rel="noreferrer"
            target="_blank"
            v-for="download in page.downloads"
          >
            <img alt="PDF" src="../assets/pdf.jpg" />
            <span>
              <strong>{{download.name}}</strong> (PDF Document)
            </span>
          </a>
        </div>

        <p :key="link._id" v-for="link in page.links">
          <a :href="link.url" target="_blank">{{ link.name }}</a>
          <br />
          <span v-html="link.description"></span>
        </p>
      </div>
      <div class="right">
        <template v-if="isHomePage">
          <template v-for="image in page.images">
            <img :alt="image.alt || 'Tickhill History Society'" :key="image._id" :src="image.image" />
            <span :key="image._id" class="caption" v-if="image.caption">{{image.caption}}</span>
          </template>
        </template>
        <img :alt="page.name" :src="page.image" v-if="page.image && !isArticle" />
        <div class="subs" v-if="page.subs && page.subs.length">
          <h3>Also in this section:</h3>
          <ul>
            <li :key="sub.ItemID" v-for="sub in page.subs">
              <page-link :to="sub.url">{{ sub.name }}</page-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage", "isSub", "isArticle"])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contentsection {
  padding: 2rem 2%;
}
.heading {
  display: block;
  padding: 1rem 2rem;
  background: #00367a;
  color: white;
  font-size: 5.5rem;
  line-height: 1;font-family: 'Cookie', cursive;
}

.grid {
  display: grid;
  grid-gap: 2rem;
}

.standard {
  grid-template-columns: 3fr 1.1fr;
}

.subs {
  margin-top: 1rem;
  padding: 1.5rem;
  border: 1px solid #f5f5f5;
}

.subs a:hover {
  color: #008fbe;
}

.subs ul,
.subs li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.subs li {
  margin-top: 0.5rem;
}

.subs h3 {
  color: #008fbe;
  border-bottom: 1px solid #00367a;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.breadcrumb {
  padding-bottom: 1rem;
  font-size: 1.2rem;
  opacity: 0.8;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .standard {
    display: table;
    width: 100%;
  }
  .standard > div:first-child {
    width: 70%;
    float: left;
  }
  .standard > div:last-child {
    width: 27%;
    float: left;
    margin-left: 3%;
  }
}

.padded {
  padding: 2rem 2%;
}

.right img {
  margin: auto;
  display: auto;
  margin-top: 2rem;
}

@media screen and (max-width: 750px) {
  .right {
    padding: 0 2%;
  }

  .standard {
    grid-template-columns: 1fr;
  }
  .subs {
    margin-top: 0;
  }
}
</style>
