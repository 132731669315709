<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries">
        <div class="heading" v-if="heading">{{ heading }}</div>
        <div class="content contentsection">
          <h2 v-if="isPlacesArchive || isTickhillToday">{{ page.name }}</h2>
          <div class="padded" v-html="page.main_text"></div>

          <div :key="item.ItemID" class="articles-full" v-for="item in items">
            <div class="article-preview">
              <page-link
                :to="item.url || ('file/' + item.parent + '/' + item._id)"
                class="article-left"
              >
                <img
                  :alt="item.name || item.alt || item.description"
                  :src="item.image || 'placeholder.jpg'"
                  class="article-image"
                />
                <span class="article-go">
                  <span>GO</span>
                </span>
              </page-link>
              <div class="article-right">
                <page-link
                  :to="item.url || ('file/' + item.parent + '/' + item._id)"
                  class="article-name"
                >{{ item.name || item.alt || item.description }}</page-link>
                <p class="article-intro">{{ item.summary || item.alt }}</p>
                <page-link
                  :to="item.url || ('file/' + item.parent + '/' + item._id)"
                  class="article-more"
                >Read more >></page-link>
              </div>
            </div>
          </div>

          <div v-if="isSearchPage && !items.length">
            <p>Your search did not match any results</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage", "isPlacesArchive", "isTickhillToday", "isNewsSub", "isSearchPage"]),
    items() {
      if (this.page.albums && this.page.albums.length) return this.page.albums
      else if (this.page.articles && this.page.articles.length) return this.page.articles
      return []
    },
    heading() {
      if (this.isPlacesArchive || this.isTickhillToday) return "Albums"
      else return ""
    }
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("show")
    }
  }
}
</script>

<style scoped>
.article-preview {
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  background: #e9e9e9;
  border-top: 4px solid #0092c0;
  padding: 1rem;
  margin-bottom: 2rem;
  display: table;
  width: 100%;
}
.article-left {
  float: left;
  margin-right: 5%;
  width: 30%;
  position: relative;
  line-height: 0;
}
.article-go {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 3rem;
}
.article-go span {
  background: #00ab7d;
  color: white;
  float: right;
  width: 2.5rem;
  text-align: center;
  height: 2rem;
  display: block;
  margin-right: 4px;
  margin-top: 0.5rem;
  line-height: 2rem;
}
.article-left img {
  width: 100%;
}
.article-right {
  float: left;
  width: 65%;
}

.article-name {
  color: #00367a;
  font-size: 1.8rem;
  margin-top: 1rem;
  display: block;
}
.article-more {
  display: inline-block;
  color: white;
  padding: 0.5rem 1rem;
  background: #00ab7d;
  margin-top: 2rem;
}
.hidden {
  opacity: 0;
}

.contentsection >>> h1 {
  color: #00367a;
  font-size: 2rem;
}

.contentsection >>> h2 {
  color: #00367a;
  font-size: 1.8rem;
  margin-top: 1rem;
}

.contentsection >>> h3 {
  color: #00367a;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.contentsection .padded >>> a {
  color: #008fbe;
}

.padded {
  padding-bottom: 3rem;
}
</style>
