<template>
  <section class="section">
    <div class="grid">
      <left></left>
      <div class="entries">
        <div class="heading">Newsletters</div>
        <div class="content">
          <span v-html="page.main_text"></span>

          <div class="latest" v-if="latestNewsletter">
            <page-link
              :external="!!latestNewsletter.pdf"
              :to="latestNewsletter.pdf || latestNewsletter.url"
              class="entry"
            >
              <span class="entry-name">{{ latestNewsletter.name }}</span>
              <span class="entry-info">
                <img :alt="latestNewsletter.image" :src="latestNewsletter.image" class="entry-image" />
                <span class="entry-go">GO</span>
              </span>
            </page-link>
          </div>

          <h2>Previous Newsletters</h2>

          <div class="previous" v-if="previousNewsletters.length">
            <page-link
              :external="!!newsletter.pdf"
              :key="newsletter.ItemID"
              :to="newsletter.pdf || newsletter.url"
              class="entry"
              v-for="newsletter in previousNewsletters"
            >
              <span class="entry-name">{{ newsletter.name }}</span>
              <span class="entry-info">
                <img :alt="newsletter.image" :src="newsletter.image" class="entry-image" />
                <span class="entry-go">GO</span>
              </span>
            </page-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    newsletters() {
      if (!this.page) return []
      if (!this.page.newsletters) return []
      if (!this.page.newsletters.length) return []

      var newsletters = JSON.parse(JSON.stringify(this.page.newsletters))
      return newsletters.sort((a, b) => b.date - a.date)
    },
    latestNewsletter() {
      return this.newsletters[0]
    },
    previousNewsletters() {
      return this.newsletters.slice(1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.previous {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.entry {
  display: inline-block;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.latest {
  margin-bottom: 3rem;
}

.entry-image {
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.entry-info {
  border: 4px solid #e9e9e9;
  padding: 2rem;
  padding-right: 6rem;
  position: relative;
  display: block;
}

.content >>> h1 {
  color: #00367a;
  font-size: 2rem;
}

.content >>> h2 {
  color: #00367a;
  font-size: 1.8rem;
  margin-top: 1rem;
}

.content >>> h3 {
  color: #00367a;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.contentsection .padded >>> a {
  color: #008fbe;
}

@media screen and (max-width: 750px) {
  .previous {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
